<style lang="scss" scoped>

</style>

<template>
  <div id="app" @dragstart.prevent @selectstart.prevent>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
