/**
 * @description vue状态管理
 */

import store from "vuex"
import Vue from "vue"

Vue.use(store)

export default new store.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    }
})


