<style lang="scss" scoped>
.data-single {
  display: flex;
  gap: 12px;
  .title {
    font-size: 14px;
    margin-bottom: 8px;
    color: $black-2;
  }
  .value {
    text-align: right;
    font-size: 30px;
  }
  .box {
    position: relative;
    img {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.box:nth-child(1) {
  background: $blue;
  .title {
    color: white;
  }
  .value {
    color: white;
  }
}
</style>
<template>
  <div class="data-single">
    <box-item v-for="item of list" :key="item.name" class="box">
      <div class="title">{{ item.name }}</div>
      <div class="value">{{ item.value }}</div>
      <img src="../../assets/1.png" alt="" />
    </box-item>
  </div>
</template>

<script>
export default {
  name: "data-single",
  props: {
    list: Array,
  },
};
</script>
