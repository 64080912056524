<style scoped>
.line-chart {
  width: 100%;
  height: 250px;
}
</style>

<template>
  <div class="line-chart">
    <chart-base :options="option"></chart-base>
  </div>
</template>

<script>
export default {
  name: "bar-chart",
  props: {
    list: Array,
  },
  data() {
    return {
      option: {},
    };
  },
  watch: {
    list() {
      this.loadOption();
    },
  },
  methods: {
    loadOption() {
      this.option = {
        title: {
          show: false,
        },

        legend: {
          show: false,
          orient: "horizontal",
          data: ["猜想", "预期", "实际"],
        },
        grid: {
          top: "10",
          left: "0px",
          right: "20",
          bottom: "25px",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: this.list.map((e) => e.name),
        },
        dataZoom: [
          {
            show: true,
            height: 12,
            xAxisIndex: [0],
            bottom: 10,
            start: 0,
            end: 100,
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 0,
            end: 100,
          },
        ],

        yAxis: {
          type: "value",
        },
        tooltip: {
          show: true,
          trigger: "axis",
        },
        series: [
          {
            smooth:true,
            name: "数值",
            data: this.list.map((e) => e.value),
            type: "bar",
            barWidth:20
            // areaStyle: {},
          },
        ],
        color: ["rgb(0,82,255,0.8)", "#FFCC99", "#99CC33"],
      };
    },
  },
  created() {
    this.loadOption();
  },
};
</script>