import vue from "vue"
vue.mixin({
    data(){
        return{
            spaces:[
                {
                    name:"区域1",
                    id:1
                },
                {
                    name:"区域2",
                    id:2
                },
                {
                    name:"区域3",
                    id:3
                },
                {
                    name:"区域4",
                    id:4
                },
                {
                    name:"区域5",
                    id:5
                },
                {
                    name:"区域6",
                    id:6
                },
                {
                    name:"区域7",
                    id:7
                },
                {
                    name:"区域8",
                    id:8
                },
                {
                    name:"区域9",
                    id:9
                }
            ],
            shopfloors:[
                {
                    name:"车间一",
                    id:1
                },
                {
                    name:"车间二",
                    id:2
                },
                {
                    name:"车间三",
                    id:3
                },
                {
                    name:"车间四",
                    id:4
                },
                {
                    name:"车间五",
                    id:5
                }
            ],
            compareTypes:[
                {
                    id:1,
                    name:"去年同比",
                },
                {
                    id:2,
                    name:"上月环比",
                },
                {
                    id:3,
                    name:"自由",
                },
                {
                    id:4,
                    name:"不比",
                }
            ],
            gauges:[
                {
                    id:1,
                    name:"水"
                },
                {
                    id:2,
                    name:"电"
                },
                {
                    id:3,
                    name:"气"
                }
            ],
            timeTypes:[
                {
                    id:1,
                    name:"年",
                },
                {
                    id:2,
                    name:"月",
                },
                {
                    id:3,
                    name:"周",
                },
                {
                    id:4,
                    name:"日",
                },
                {
                    id:4,
                    name:"时",
                }
            ],
            devices:[
                {
                    name:"设备一",
                    id:1
                },
                {
                    name:"设备二",
                    id:2
                },
                {
                    name:"设备三",
                    id:3
                },
                {
                    name:"设备四",
                    id:4
                },
                {
                    name:"设备五",
                    id:5
                },  {
                    name:"设备六",
                    id:6
                },  {
                    name:"设备七",
                    id:7
                },  {
                    name:"设备八",
                    id:8
                },  {
                    name:"设备九",
                    id:9
                },
                {
                    name:"设备十",
                    id:10
                }
            ],
            tenants:[
                {
                    name:"租户一"
                },
                {
                    name:"租户二"
                },
                {
                    name:"租户三"
                },
                {
                    name:"租户四"
                },
                {
                    name:"租户五"
                },  {
                    name:"租户六"
                },  {
                    name:"租户七"
                },  {
                    name:"租户八"
                },  {
                    name:"租户九"
                },
                {
                    name:"租户十"
                }
            ],
            stores:[
                {
                    name:"门店一"
                },
                {
                    name:"门店二"
                },
                {
                    name:"门店三"
                },
                {
                    name:"门店四"
                },
                {
                    name:"门店五"
                },  {
                    name:"门店六"
                },  {
                    name:"门店七"
                },  {
                    name:"门店八"
                },  {
                    name:"门店九"
                },
                {
                    name:"门店十"
                }
            ]

        }
    }
})