<style lang="scss" scoped>
.data-single {
  display: flex;
  gap: 12px;
  .title {
    font-size: 14px;
    margin-bottom: 8px;
    color: $black-2;
  }
  .value {
    text-align: right;
    font-size: 30px;
  }
}
</style>
<template>
  <div class="data-single">
    <box-item v-for="item of list" :key="item.name">
      <div class="title">{{ item.name }}</div>
      <div class="value">
        <el-progress type="circle" color="rgb(0,82,217)" :format="item.df?(e)=>e:(e)=>e+'%'" :percentage="item.value"></el-progress>
      </div>
    </box-item>
  </div>
</template>

<script>
export default {
  name: "progress-section",
  props: {
    list: Array,
  },
};
</script>
