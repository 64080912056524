import Vue from "vue";
import App from "./App";

//vuex & router
import router from "./router";
import store from "./store";

//iconfont
import './design/iconfont/iconfont.css'

//element ui 
import ElementUI from "element-ui";
Vue.use(ElementUI);

//公共样式
import "./design/index.scss";

//公共组件
import "./components";

//mixin
import "./mixin"

import("axios").then(result => {
  console.log(result)
})

new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});

