<style lang="scss" scoped>
//背景块
.box-item {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding: 12px;
  .main {
    height: 100%;
  }
  .box-item-title {
    font-size: 16px;
    // font-weight: bold;
    color: $black-2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }
}
</style>

<template>
  <div class="box-item">
    <div class="box-item-title" v-if="title != ''">
      <div>{{ title }}</div>
      <slot name="header"></slot>
    </div>
    <div class="main">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "box-item",
  props: {
    title: {
      default: "",
      type: String,
    },
  },
};
</script>
